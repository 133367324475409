import { useState, useEffect } from 'react';

function useSticky() {
  const [headerState, setHeaderState] = useState('normal');

  useEffect(() => {
    let lastScrollY = window.pageYOffset;
    const normalThreshold = 200; // Ajusta este valor para decidir cuándo desaparece el header normal
    const stickyThreshold = 400; // Ajusta este valor para decidir cuándo aparece el header sticky

    const handleScroll = () => {
      const currentScrollY = window.pageYOffset;
      
      if (currentScrollY < normalThreshold) {
        setHeaderState('normal');
      } else if (currentScrollY < stickyThreshold) {
        setHeaderState('hidden');
      } else {
        setHeaderState('sticky');
      }

      lastScrollY = currentScrollY;
    };

    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return headerState;
}

export default useSticky;