import BaconBar from '../assets/img/Partners/Bacon Bar.webp'
import BaronBalche from '../assets/img/Partners/BaronBalche.webp'
import ChateauC from '../assets/img/Partners/ChateauC.webp'
import COEN from '../assets/img/Partners/ConciertoEnologico.webp'
import ElyCastro from '../assets/img/Partners/Ely Castro.webp'
import BosqueReal from '../assets/img/Partners/GBosqueReal.webp'
import Marques from '../assets/img/Partners/Marques.webp'
import MMC from '../assets//img/Partners/MMC.webp'
import prudencianas from '../assets/img/Partners/Pudencianas.webp'
import SJL from '../assets/img/Partners/SJL.webp'
import KA from '../assets/img/Partners/10KA.webp'
import TierraAdentro from '../assets/img/Partners/Tierra-Adentro.webp'
import arbitrum from '../assets/img/Partners/Arbitrum.webp'
import SpaceArt from '../assets/img/Partners/SpacerArt.webp'
import Tokenomia from '../assets/img/Partners/Tokenomia.webp'
import XHype from '../assets/img/Partners/XHype.webp'

import '../components/Carousel.css'

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import { Autoplay, Pagination, Navigation } from 'swiper/modules';

export default function App() {
  return (
    <>
      <Swiper
        autoplay={{
          delay: 200,
          disableOnInteraction: false,
        }}
        speed={3000}
        loop={true}
        slidesPerView={3}
        spaceBetween={60}
        breakpoints={{
          640: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 4,
            spaceBetween: 50,
          },
          1024: {
            slidesPerView: 5,
            spaceBetween: 50,
          },
        }}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
      >


        <SwiperSlide>
          <a href="https://docs.enotoken.io/partnerships-and-collaborations/xhype" rel="noopener noreferrer">
            <img src={XHype} alt="Tokenomic" />
          </a>
        </SwiperSlide>

        <SwiperSlide>
          <a href="https://docs.enotoken.io/partnerships-and-collaborations/tokenomio.pro" rel="noopener noreferrer">
            <img src={Tokenomia} alt="Tokenomic" />
          </a>
        </SwiperSlide>

        <SwiperSlide>
          <a href="https://docs.enotoken.io/partnerships-and-collaborations/spaceart" rel="noopener noreferrer">
            <img src={SpaceArt} alt="SpaceArt" />
          </a>
        </SwiperSlide>

        <SwiperSlide>
          <a href="https://docs.enotoken.io/partnerships-and-collaborations/arbitrum" rel="noopener noreferrer">
            <img src={arbitrum} alt="Arbitrum" />
          </a>
        </SwiperSlide>

        <SwiperSlide>
          <a href="https://docs.enotoken.io/partnerships-and-collaborations/10ka" rel="noopener noreferrer">
            <img src={KA} alt="10KA" />
          </a>
        </SwiperSlide>

        <SwiperSlide>
          <a href="https://docs.enotoken.io/partnerships-and-collaborations/bosque-real" rel="noopener noreferrer">
            <img src={BosqueReal} alt="Bosque Real" />
          </a>
        </SwiperSlide>

        <SwiperSlide>
          <a href="https://docs.enotoken.io/partnerships-and-collaborations/chateau-camou" rel="noopener noreferrer">
            <img src={ChateauC} alt="Chateau Camou" />
          </a>
        </SwiperSlide>

        <SwiperSlide>
          <a href="https://docs.enotoken.io/partnerships-and-collaborations/bacon-bar" rel="noopener noreferrer">
            <img src={BaconBar} alt="Bacon Bar" />
          </a>
        </SwiperSlide>

        <SwiperSlide>
          <a href="https://docs.enotoken.io/partnerships-and-collaborations/ely-castro" rel="noopener noreferrer">
            <img src={ElyCastro} alt="Ely Castro" />
          </a>
        </SwiperSlide>

        <SwiperSlide>
          <a href="https://docs.enotoken.io/partnerships-and-collaborations/mario-martin-del-campo" rel="noopener noreferrer">
            <img src={MMC} alt="Mario Martín Del Campo" />
          </a>
        </SwiperSlide>

        <SwiperSlide>
          <a href="https://docs.enotoken.io/partnerships-and-collaborations/las-pudencianas" rel="noopener noreferrer">
            <img src={prudencianas} alt="Las Prudencianas" />
          </a>
        </SwiperSlide>

        <SwiperSlide>
          <a href="https://docs.enotoken.io/partnerships-and-collaborations/marques-de-grinon" rel="noopener noreferrer">
            <img src={Marques} alt="Marques de Griñón" />
          </a>
        </SwiperSlide>

        <SwiperSlide>
          <a href="https://docs.enotoken.io/partnerships-and-collaborations/san-jose-la-vista" rel="noopener noreferrer">
            <img src={SJL} alt="San José La Vista" />
          </a>
        </SwiperSlide>

        <SwiperSlide>
          <a href="https://docs.enotoken.io/partnerships-and-collaborations/concierto-enologico" rel="noopener noreferrer">
            <img src={COEN} alt="Concierto Enologico" />
          </a>
        </SwiperSlide>

        <SwiperSlide>
          <a href="https://docs.enotoken.io/partnerships-and-collaborations/baron-balche" rel="noopener noreferrer">
            <img src={BaronBalche} alt="Baron Balche" />
          </a>
        </SwiperSlide>

        <SwiperSlide>
          <a href="https://docs.enotoken.io/partnerships-and-collaborations/tierra-adentro" rel="noopener noreferrer">
            <img src={TierraAdentro} alt="Tierra Adentro" />
          </a>
        </SwiperSlide>
      </Swiper>
    </>
  );
}
