import React from 'react';
import BackNFT from "../Videos/BackBadgesNFT.mp4";
import BadgeCover from "../assets/img/Masks/BadgesNewsletter.webp"; // Añadir la imagen de fondo
import "./BadgesNewsletter.css";

function badges() {
  return (
    <>
      {/* ======= Badge ======= */}
      <section className="badge__video">
        <div className="badge__contents">
          <div className="badge___left">
            <div className="video-container">
              <video
                src={BackNFT}
                type="video/mp4"
                loop
                muted
                autoPlay
                playsInline
                className="badgeLeft"
              />
              <img src={BadgeCover} alt="Badge Cover" className="badgeCover" />
            </div>
          </div>
          <div className="badge__right">
            <h2 className="section__title-badge">
              Mint Your NFT NOW
            </h2>
            <span className="span__subtitle-badge">
              ENO Network gives you the ability to mint your NFTs <br/>
              create, trade, and show all in one platform.
            </span>
            <a href="https://app.eno.network/" rel="noreferrer">
              <button className="hero__btn btn-alternative">Get My NFT!</button>
            </a>
          </div>
        </div>
      </section>
      {/* ======= Badge Mobile ======= */}
      <section className="badge__video-mobile">
        <div className="badge__contents">
          <div className="badge__left">
            <h2 className="section__title-badge">
              Mint your NFT NOW
            </h2>
          </div>
          <div className="badge___right">
            <div className="video-container">
              <img src={BadgeCover} alt="Badge Cover" className="badgeCover" />
              <video
                src={BackNFT}
                type="video/mp4"
                loop
                muted
                autoPlay
                playsInline
                className="badgeRight"
              />
            </div>
            <span className="span__subtitle-badge">
              ENO Network gives you the ability to mint your NFTs
              create, trade, and show all in one platform.
            </span>
            <a href="https://app.eno.network/" rel="noreferrer">
              <button className="hero__btn btn-alternative">Get My NFT!</button>
            </a>
          </div>
        </div>
      </section>
    </>
  );
}

export default badges;
