import React, { useRef, useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';

const LazyVideo = ({ src, poster, type = 'video/mp4', className }) => {
  const [inViewRef, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });
  const videoRef = useRef(null);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (inView && !isLoaded) {
      videoRef.current.src = src;
      videoRef.current.load();
      setIsLoaded(true);
    }
  }, [inView, src, isLoaded]);

  return (
    <video
      ref={(el) => {
        videoRef.current = el;
        inViewRef(el);
      }}
      poster={poster}
      autoPlay
      loop
      muted
      playsInline
      className={className}
    >
      {isLoaded && <source src={src} type={type} />}
    </video>
  );
};

export default LazyVideo;