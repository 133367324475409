import React from 'react';
import './App.css';
import ContentSystem from './ContentSystem';
import ChatWidget from "./Templates/chatWidget";

// Componente de límite de error simple
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error("Error caught by ErrorBoundary:", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <h1>Something went wrong. Please try refreshing the page.</h1>;
    }

    return this.props.children;
  }
}

function App() {
  return (
    <div className="App">
      <ErrorBoundary>
        <ContentSystem />
        <ChatWidget apiUrl="https://apiagnes.enotoken.io" />
      </ErrorBoundary>
    </div>
  );
}

export default App;