import React from 'react';
import { LazyLoadImage } from "react-lazy-load-image-component";

const OptimizedImage = ({ src, alt, width, height, ...props }) => {
  const webpSrc = src.replace(/\.(png|jpg|jpeg)$/, '.webp');
  
  return (
    <picture>
      <source srcSet={webpSrc} type="image/webp" />
      <LazyLoadImage
        src={src}
        alt={alt}
        width={width}
        height={height}
        effect="blur"
        {...props}
      />
    </picture>
  );
};

export default OptimizedImage;